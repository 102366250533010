@font-face {
  font-family: 'proximanova-regular';
  src: url('./assets/fonts/proximanova_regular_macroman/proximanova-regular-webfont.woff2')
      format('woff2'),
    url('./assets/fonts/proximanova_regular_macroman/proximanova-regular-webfont.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'proximanova-regular';
}
